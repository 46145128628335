// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-development-how-i-tdd-an-accessible-react-accordion-mdx": () => import("./../../../src/pages/blog/development/how-i-tdd-an-accessible-react-accordion.mdx" /* webpackChunkName: "component---src-pages-blog-development-how-i-tdd-an-accessible-react-accordion-mdx" */),
  "component---src-pages-blog-development-playing-with-polygons-the-iceberg-story-mdx": () => import("./../../../src/pages/blog/development/playing-with-polygons---the-iceberg-story.mdx" /* webpackChunkName: "component---src-pages-blog-development-playing-with-polygons-the-iceberg-story-mdx" */),
  "component---src-pages-blog-development-what-i-consider-when-writing-css-mdx": () => import("./../../../src/pages/blog/development/what-i-consider-when-writing-css.mdx" /* webpackChunkName: "component---src-pages-blog-development-what-i-consider-when-writing-css-mdx" */),
  "component---src-pages-blog-games-2-hours-in-the-witness-mdx": () => import("./../../../src/pages/blog/games/2-hours-in-the-witness.mdx" /* webpackChunkName: "component---src-pages-blog-games-2-hours-in-the-witness-mdx" */),
  "component---src-pages-blog-games-aspects-of-games-mdx": () => import("./../../../src/pages/blog/games/aspects-of-games.mdx" /* webpackChunkName: "component---src-pages-blog-games-aspects-of-games-mdx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-personal-tsx": () => import("./../../../src/pages/personal.tsx" /* webpackChunkName: "component---src-pages-personal-tsx" */),
  "component---src-pages-presentations-a-11-y-tsx": () => import("./../../../src/pages/presentations/a11y.tsx" /* webpackChunkName: "component---src-pages-presentations-a-11-y-tsx" */),
  "component---src-pages-presentations-index-tsx": () => import("./../../../src/pages/presentations/index.tsx" /* webpackChunkName: "component---src-pages-presentations-index-tsx" */),
  "component---src-pages-professional-tsx": () => import("./../../../src/pages/professional.tsx" /* webpackChunkName: "component---src-pages-professional-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-projects-tinkering-a-11-y-resources-tsx": () => import("./../../../src/pages/projects/tinkering/a11y-resources.tsx" /* webpackChunkName: "component---src-pages-projects-tinkering-a-11-y-resources-tsx" */),
  "component---src-pages-projects-tinkering-first-react-project-tsx": () => import("./../../../src/pages/projects/tinkering/first-react-project.tsx" /* webpackChunkName: "component---src-pages-projects-tinkering-first-react-project-tsx" */),
  "component---src-pages-projects-tinkering-hexagons-v-2-tsx": () => import("./../../../src/pages/projects/tinkering/hexagons-v2.tsx" /* webpackChunkName: "component---src-pages-projects-tinkering-hexagons-v-2-tsx" */),
  "component---src-pages-projects-tinkering-hexout-tsx": () => import("./../../../src/pages/projects/tinkering/hexout.tsx" /* webpackChunkName: "component---src-pages-projects-tinkering-hexout-tsx" */),
  "component---src-pages-projects-tinkering-index-tsx": () => import("./../../../src/pages/projects/tinkering/index.tsx" /* webpackChunkName: "component---src-pages-projects-tinkering-index-tsx" */),
  "component---src-pages-projects-tinkering-playing-with-hexagons-tsx": () => import("./../../../src/pages/projects/tinkering/playing-with-hexagons.tsx" /* webpackChunkName: "component---src-pages-projects-tinkering-playing-with-hexagons-tsx" */),
  "component---src-pages-projects-tinkering-yahtzee-redux-tsx": () => import("./../../../src/pages/projects/tinkering/yahtzee-redux.tsx" /* webpackChunkName: "component---src-pages-projects-tinkering-yahtzee-redux-tsx" */),
  "component---src-pages-projects-university-behaviourism-essay-tsx": () => import("./../../../src/pages/projects/university/behaviourism-essay.tsx" /* webpackChunkName: "component---src-pages-projects-university-behaviourism-essay-tsx" */),
  "component---src-pages-projects-university-dcu-campus-tour-tsx": () => import("./../../../src/pages/projects/university/dcu-campus-tour.tsx" /* webpackChunkName: "component---src-pages-projects-university-dcu-campus-tour-tsx" */),
  "component---src-pages-projects-university-first-website-tsx": () => import("./../../../src/pages/projects/university/first-website.tsx" /* webpackChunkName: "component---src-pages-projects-university-first-website-tsx" */),
  "component---src-pages-projects-university-griffin-game-tsx": () => import("./../../../src/pages/projects/university/griffin-game.tsx" /* webpackChunkName: "component---src-pages-projects-university-griffin-game-tsx" */),
  "component---src-pages-projects-university-hexagon-clone-tsx": () => import("./../../../src/pages/projects/university/hexagon-clone.tsx" /* webpackChunkName: "component---src-pages-projects-university-hexagon-clone-tsx" */),
  "component---src-pages-projects-university-index-tsx": () => import("./../../../src/pages/projects/university/index.tsx" /* webpackChunkName: "component---src-pages-projects-university-index-tsx" */),
  "component---src-pages-projects-university-liam-myles-indent-tsx": () => import("./../../../src/pages/projects/university/liam-myles-indent.tsx" /* webpackChunkName: "component---src-pages-projects-university-liam-myles-indent-tsx" */),
  "component---src-pages-projects-university-safari-game-tsx": () => import("./../../../src/pages/projects/university/safari-game.tsx" /* webpackChunkName: "component---src-pages-projects-university-safari-game-tsx" */),
  "component---src-pages-projects-university-shatter-animation-tsx": () => import("./../../../src/pages/projects/university/shatter-animation.tsx" /* webpackChunkName: "component---src-pages-projects-university-shatter-animation-tsx" */),
  "component---src-pages-projects-university-steampunk-site-tsx": () => import("./../../../src/pages/projects/university/steampunk-site.tsx" /* webpackChunkName: "component---src-pages-projects-university-steampunk-site-tsx" */),
  "component---src-pages-projects-university-thesis-tsx": () => import("./../../../src/pages/projects/university/thesis.tsx" /* webpackChunkName: "component---src-pages-projects-university-thesis-tsx" */),
  "component---src-pages-projects-university-twitter-heatmap-tsx": () => import("./../../../src/pages/projects/university/twitter-heatmap.tsx" /* webpackChunkName: "component---src-pages-projects-university-twitter-heatmap-tsx" */),
  "component---src-pages-projects-university-yahtzee-tsx": () => import("./../../../src/pages/projects/university/yahtzee.tsx" /* webpackChunkName: "component---src-pages-projects-university-yahtzee-tsx" */),
  "component---src-pages-styleguide-tsx": () => import("./../../../src/pages/styleguide.tsx" /* webpackChunkName: "component---src-pages-styleguide-tsx" */),
  "component---src-pages-testing-tsx": () => import("./../../../src/pages/testing.tsx" /* webpackChunkName: "component---src-pages-testing-tsx" */)
}

