import React from "react"
import styled from "styled-components"

const ImageGridWrapper = styled.ol`
  display: flex;
  flex-wrap: wrap;
`
const ImageWrapper = styled.li`
  flex: 1 0 50%;
  padding: 1rem;
`

const Images: React.FC = ({ children }) => {
  return (
    <ImageGridWrapper>
      {React.Children.map(children, child => {
        return <ImageWrapper>{child}</ImageWrapper>
      })}
    </ImageGridWrapper>
  )
}

export default Images
